/* ---------- Global Container ---------- */
.home-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem 0 1rem;  /* Equal padding on left and right */
}

/* ---------- Hero Section ---------- */
.hero-section {
    display: flex; /* Horizontal layout for desktop */
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
    background-color: #e5ebe3;
    border-bottom: 5px solid #00bcd4;
}

.logo-left, .logo-right {
    width: 300px;
    height: auto;
}

.logo-right {
    transform: scaleX(-1);
}

.text-content {
    text-align: center;
    flex-grow: 1;
    margin: 0 2rem;
}

.text-content h1 {
    font-size: 2.5rem;
    color: #1e2a38;
    margin: 0;
}

.text-content p {
    font-size: 1.2rem;
    color: #333;
    margin: 0.5rem 0 0;
}

/* ---------- Resources Section ---------- */
.resources-section {
    padding: 2rem 4rem;
    background-color: #f7f7f7;
    text-align: center;
}

.resources-section h2 {
    color: #1e2a38;
    margin-bottom: 1.5rem;
}

.resources-grid {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
}

.resource-card {
    background-color: white;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    flex: 1;
    max-width: 250px;
    text-align: left;
}

.resource-card h3 {
    color: #00bcd4;
    margin-top: 0;
}

.resource-card p {
    color: #333;
    font-size: 0.9rem;
    margin: 0.5rem 0;
}

.resource-card a {
    color: #00bcd4;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9rem;
}

.resource-card a:hover {
    text-decoration: underline;
}

/* ---------- Carousel Section ---------- */
.carousel-section {
    margin: 2rem 4rem;
}

/* ---------- Main Content ---------- */
.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem;
}

.company-description {
    width: 45%;
    text-align: left;
    margin-left: 0;
}

.contact-us {
    width: 45%;
    margin-left: auto;
    text-align: right;
}

.company-description h2,
.contact-us h2 {
    color: #1e2a38;
}

footer {
    text-align: center;
    padding: 1rem;
    background-color: #1e2a38;
    color: white;
}

/* ---------- Mobile Styles ---------- */
@media (max-width: 768px) {
    /* Ensure container remains centered with equal padding */
    .home-page {
        padding: 0 2rem 0 1rem;
    }
    
    /* Hero Section: Switch to vertical layout with a single logo */
    .hero-section {
        flex-direction: column;
        padding: 1rem 2rem;
        text-align: center;
    }
    .hero-section img.logo-right {
        display: none;
    }
    .hero-section img.logo-left {
        width: 150px;
    }
    .text-content h1 {
        font-size: 1.8rem;
    }
    .text-content p {
        font-size: 1rem;
    }
    
    /* Hide Carousel on Mobile */
    .carousel-section {
        display: none;
    }
    
    /* Resources Section: Stack cards vertically */
    .resources-section {
        padding: 1rem 2rem;
    }
    .resources-grid {
        flex-direction: column;
        align-items: center;
    }
    .resource-card {
        width: 100%;
        max-width: 90%;
        margin-bottom: 1rem;
        min-height: 220px; /* Ensures uniform card height */
        box-sizing: border-box;
    }
    
    /* Main Content: Stack sections vertically */
    .content {
        flex-direction: column;
        padding: 1rem 2rem;
    }
    .company-description,
    .contact-us {
        width: 100%;
        text-align: center;
        margin: 1rem 0;
    }
}
