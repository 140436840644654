/* Container for the entire page */
.favorites-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    
    /* Ensures text can wrap and no horizontal scroll occurs */
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  
  /* Page Title */
  .favorites-container h1 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  /* Each "favorites-section" is visually separated */
  .favorites-section {
    background-color: #f9f9f9;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Section Titles */
  .favorites-section h2 {
    font-size: 1.4rem;
    color: #007bff;
    margin-bottom: 10px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }
  
  /* Remove bullet styling for ul, ensure spacing */
  .favorites-section ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  
  /* Card-like style for each tender */
  .tender-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
  }
  
  /* Spacing for text lines in each card */
  .tender-card p {
    margin: 5px 0;
    color: #444;
    font-size: 1rem;
  }
  
  /* Link styling (Portal Link) */
  .tender-card a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    word-wrap: break-word; /* ensures long URLs wrap */
  }
  
  .tender-card a:hover {
    text-decoration: underline;
  }
  
  /* Message when no favorites found */
  .no-favorites {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
  }
  
  /* Mobile-friendly adjustments */
  @media (max-width: 600px) {
    .favorites-container {
      width: 90%;
      padding: 15px;
    }
    
    .favorites-section {
      padding: 10px;
    }
  
    .favorites-section h2 {
      font-size: 1.2rem;
    }
  
    .tender-card p {
      font-size: 0.95rem;
    }
  }
  