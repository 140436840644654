/* ---------- Global Container ---------- */
.subscription-pricing-container {
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
    text-align: center;
}

/* ---------- Headings and Text ---------- */
.subscription-pricing-container h1 {
    color: #1e2a38;
    margin-bottom: 1rem;
}

.subscription-pricing-container p {
    color: #555;
    margin-bottom: 2rem;
}

/* ---------- Pricing Cards Grid (Desktop) ---------- */
.pricing-cards {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
}

/* Each Pricing Card (Desktop) */
.pricing-card {
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 1.5rem;
    width: 30%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.pricing-card h2 {
    color: #00bcd4;
    margin-bottom: 1rem;
}

.pricing-card p {
    color: #333;
    margin-bottom: 1rem;
}

.pricing-card h3 {
    color: #1e2a38;
    margin-bottom: 1rem;
}

.pricing-card button {
    background-color: #00bcd4;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.pricing-card button:hover {
    background-color: #0198a1;
}

/* ---------- Mobile Styles ---------- */
@media (max-width: 768px) {
    /* Stack pricing cards vertically */
    .pricing-cards {
        flex-direction: column;
        gap: 1rem;
    }
    
    /* Make each card nearly full-width with a bit of margin */
    .pricing-card {
        width: 90%;
        margin: 0 auto;
    }
    
    /* Optionally, reduce padding and font sizes on mobile for a lighter feel */
    .pricing-card {
        padding: 1rem;
    }
    
    .pricing-card h2 {
        font-size: 1.2rem;
    }
    
    .pricing-card p {
        font-size: 0.9rem;
    }
    
    .pricing-card h3 {
        font-size: 1rem;
    }
    
    .pricing-card button {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }
}
