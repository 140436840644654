/* src/components/Services.css */
.services-container {
    padding: 2rem;
    max-width: 900px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.services-container h1 {
    text-align: center;
    color: #1e2a38;
    margin-bottom: 2rem;
}

.service {
    margin-bottom: 2rem;
}

.service h2 {
    color: #00bcd4;
    margin-bottom: 1rem;
}

.service p {
    color: #333;
    line-height: 1.6;
}
