/* Ensure full width and center alignment */
.active-plan-container {
  max-width: 600px;
  width: 90%; /* Ensures it adapts to smaller screens */
  margin: 0 auto; /* Centers the container */
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Heading Styling */
.active-plan-container h1 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

/* Plan Details */
.plan-details, .no-plan-details {
  font-size: 1rem;
  text-align: left; /* Align content left for better readability */
  padding: 15px;
}

/* Plan Text Styling */
.plan-details p, .no-plan-details p {
  margin: 10px 0;
  color: #555;
}

/* Buttons */
.manage-subscription-button, .subscribe-button {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 20px auto;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.manage-subscription-button:hover, .subscribe-button:hover {
  background-color: #0056b3;
}

/* 🔹 Mobile Responsiveness */
@media screen and (max-width: 600px) {
  .active-plan-container {
    width: 95%;
    padding: 15px;
  }

  .active-plan-container h1 {
    font-size: 1.5rem;
  }

  .plan-details, .no-plan-details {
    font-size: 0.95rem;
  }

  .manage-subscription-button, .subscribe-button {
    font-size: 0.9rem;
    padding: 8px;
  }
}
