/* MyProfile.css */
.profile-page {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-page h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .profile-page h2 {
    font-size: 18px;
    margin-top: 15px;
    color: #333;
  }
  
  .profile-page p {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .profile-page label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .profile-page input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .profile-page button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .profile-page button:hover {
    background-color: #0056b3;
  }
  
  .profile-page .cancel-btn {
    background-color: #6c757d;
  }
  
  .profile-page .cancel-btn:hover {
    background-color: #545b62;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .profile-page {
      width: 90%;
      padding: 15px;
    }
  
    .profile-page input {
      font-size: 14px;
    }
  
    .profile-page button {
      font-size: 14px;
    }
  }
  