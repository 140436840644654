/* ReportDetailsPage.css */
.report-details-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .report-details-container h1 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  .report-item {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 4px;
  }
  
  .report-item h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .report-item label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .report-item select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .report-item input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .report-item textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .report-details-container button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #28a745;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .report-details-container button:hover {
    background-color: #218838;
  }
  