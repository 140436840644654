/* A background color consistent with your site (e.g. #e5ebe3). 
   Adjust if you prefer #f7f7f7 or another site color. */
   .login-background {
    background: #e5ebe3; 
    min-height: 100vh;
    display: flex; 
    align-items: center; 
    justify-content: center; 
  }
  
  /* Centers the login card on the page */
  .login-page-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
  
  /* The white card with shadow for the login form */
  .login-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    padding: 2rem;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  /* Heading & subtitle styling */
  .login-card h2 {
    color: #1e2a38;
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
  }
  
  .login-subtitle {
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
  
  /* Slight margin above the Firebase UI container */
  #firebaseui-auth-container {
    margin-top: 1rem;
  }
  
  /* ---------- Force disclaimers OFF ---------- */
  
  /* Hide disclaimers about message rates, TOS, or privacy */
  .firebaseui-id-page-phone-sign-in .firebaseui-info-bar,
  .firebaseui-id-page-phone-sign-in .firebaseui-subtitle,
  .firebaseui-id-page-phone-sign-in .firebaseui-text-disclaimer {
    display: none !important;
  }
  
  /* Also hide default label for phone number */
  .firebaseui-id-page-phone-sign-in .mdl-textfield__label {
    display: none !important;
  }
  
  /* ---------- +91 INSIDE the input ---------- */
  
  /* Place the +91 text absolutely inside the input */
  .firebaseui-id-phone-country-code {
    position: absolute !important;
    margin-left: 12px !important; /* Adjust for perfect alignment */
    margin-top: 12px !important;  /* Tweak for vertical alignment */
    color: #666 !important;
    pointer-events: none !important;
    font-size: 1rem !important;
  }
  
  /* Give the phone input enough left padding to avoid overlap with +91 */
  .firebaseui-id-phone-number .firebaseui-input {
    padding-left: 55px !important; /* Adjust if +91 overlaps typed text */
  }
  
  /* ---------- Mobile (smaller screens) ---------- */
  @media (max-width: 480px) {
    .login-card {
      padding: 1.5rem;
      max-width: 90%;
    }
    .login-card h2 {
      font-size: 1.5rem;
    }
    .login-subtitle {
      font-size: 0.9rem;
    }
  
    .firebaseui-id-phone-country-code {
      margin-left: 10px !important;
      margin-top: 10px !important;
      font-size: 0.9rem !important;
    }
    .firebaseui-id-phone-number .firebaseui-input {
      padding-left: 50px !important;
    }
  }
  