.footer-container {
    padding: 5px;
    background-color: #e5ebe3;
    text-align: center;
    margin-top: auto;
  }

  .footer-container p {
    color: black;
    margin: 3px 0; /* Reduced margin for text */
}
  
  .footer-links {
    margin-top: 3px;
  }
  
  .footer-links a {
    margin: 0 5px;
    color: #555;
    text-decoration: none;
    font-size: 0.9em;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  