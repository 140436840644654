/* ================= Desktop Styles ================= */
.navbar {
    background-color: #1e2a38;
    color: white;
    padding: 1rem;
    position: relative; /* For absolute dropdowns */
}

.desktop-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* Desktop Left Group */
.desktop-left {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Logo link container */
.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    gap: 0.5rem;
}

/* Logo styling */
.logo {
    height: 50px;
    width: auto;
}

/* Text container */
.brand-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.2;
}

/* Title styling */
.brand-title {
    font-size: 1rem;
    font-weight: bold;
    color: #00bcd4;
    margin: 0;
    margin-left: 5px;
    text-align: center;
}

/* Tagline styling */
.brand-tagline {
    font-size: 0.85rem;
    color: #cccccc;
    margin: 0;
}

/* Tracker Dashboard Button */
.tender-tracker-btn {
    padding: 0.5rem 1rem;
    background-color: #00bcd4;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    margin-left: 1rem;
    transition: background-color 0.3s ease;
}

.tender-tracker-btn:hover {
    background-color: #0097a7;
}

/* Reserved space if no dashboard button */
.tender-tracker-btn.empty-space {
    visibility: hidden;
}

/* Navigation Links */
.nav-links {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    margin-left: 1rem;
}

.nav-links a,
.nav-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem;
    transition: color 0.3s ease;
}

.nav-links a:hover,
.nav-link:hover {
    color: #00bcd4;
}

/* Desktop Right Group (Login/User) */
.desktop-right {
    display: flex;
    align-items: center;
}

.login-btn {
    padding: 0.5rem 1rem;
    background-color: #00bcd4;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #0097a7;
}

/* Ensure the login/user element is pushed to the far right */
.login-item {
    margin-left: auto;
}

/* Resources Dropdown */
.resources-dropdown {
    position: relative;
}

/* Add a small downward arrow after the Resources link */
.resources-dropdown .nav-link::after {
    content: " ▼";
    font-size: 0.8em;
    margin-left: 0.3rem;
    color: inherit;
}

.resources-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #2a3d4e;
    padding: 0.8rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    z-index: 10;
}

.resources-dropdown-menu li {
    list-style: none;
}

.resources-dropdown-menu a {
    color: white;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0.4rem 0.6rem;
    display: block;
    transition: background-color 0.3s ease;
}

.resources-dropdown-menu a:hover {
    background-color: #00bcd4;
    color: #1e2a38;
}

/* User Dropdown (Desktop) */
.user-dropdown {
    position: relative;
}

.user-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #2a3d4e;
    padding: 0.8rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    z-index: 10;
    min-width: 180px;
}

.user-dropdown-menu li {
    list-style: none;
}

.user-dropdown-menu a {
    color: white;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0.4rem 0.6rem;
    display: block;
    transition: background-color 0.3s ease;
}

.user-dropdown-menu a:hover {
    background-color: #00bcd4;
    color: #1e2a38;
}

/* User Icon Container */
.user-icon-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.4rem;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.1);
    transition: background-color 0.3s ease;
}

.user-icon-container:hover {
    background-color: rgba(255,255,255,0.2);
}

.user-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

/* Logout Button */
.logout-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0.4rem 0.6rem;
    text-align: left;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #00bcd4;
    color: #1e2a38;
}

/* ================= Mobile Styles ================= */
/* By default, show desktop nav and hide mobile nav */
.desktop-nav {
    display: block;
}
.mobile-nav {
    display: none;
}

@media (max-width: 768px) {
    /* Hide Desktop, Show Mobile */
    .desktop-nav {
        display: none;
    }
    .mobile-nav {
        display: block;
        position: relative; /* For absolute positioning of dropdowns */
    }
    
    /* Mobile container styling */
    .mobile-container {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        background-color: #1e2a38;
        /* Using explicit ordering; no justify-content: space-between */
    }
    
    /* Explicit flex ordering for mobile items */
    .mobile-container > .hamburger-menu {
        order: 1;
        margin-right: 0;  /* Extreme left */
    }
    .mobile-container > .mobile-logo {
        order: 2;
        margin-right: 0.5rem;  /* Small gap between hamburger and logo */
    }
    .mobile-container > .mobile-dashboard {
        order: 3;
        margin-left: 0.5rem;
        font-size: 0.8rem;      /* Slightly smaller font size */
        padding: 0.3rem 0.6rem;  /* Reduced padding */
    }
    .mobile-container > .mobile-user,
    .mobile-container > .mobile-login {
        order: 4;
        margin-left: auto;
    }
    
    /* Hamburger Menu */
    .hamburger-menu {
        background: none;
        border: none;
        color: white;
        font-size: 2rem; /* More prominent */
        cursor: pointer;
    }
    
    /* Mobile Logo (smaller) */
    .mobile-logo .logo-small,
    .logo-small {
        height: 30px;
        width: auto;
    }
    
    /* Mobile Tracker Dashboard Button (already adjusted above) */
    .mobile-dashboard {
        /* Uses updated font-size and padding as set in ordering */
    }
    
    /* Mobile Login Button */
    .mobile-login {
        font-size: 0.9rem;
        padding: 0.4rem 0.8rem;
    }
    
    /* Mobile Navigation Menu (from Hamburger) */
    .mobile-nav-menu {
        position: absolute; /* Overlay the dropdown without affecting navbar height */
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        max-width: 300px;
        background-color: #1e2a38;
        padding: 1rem;
        z-index: 20;
        border-radius: 5px;
    }
    
    .mobile-nav-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    /* Mobile dropdown menu link styles */
    .mobile-nav-menu a {
        color: #ccc; /* Softer text color */
        text-decoration: none;
        font-size: 1rem;
        transition: color 0.3s ease;
    }
    
    .mobile-nav-menu a:hover {
        color: #fff;
    }
    
    /* Mobile Dropdowns for Resources and User */
    .mobile-resources-dropdown,
    .mobile-user-dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #2a3d4e;
        padding: 0.8rem;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0,0,0,0.2);
        z-index: 30;
        width: 180px;
    }
    
    .mobile-resources-dropdown li,
    .mobile-user-dropdown li {
        padding: 0.5rem 0;
    }
}
