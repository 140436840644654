.gem-categories-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.page-title {
    text-align: center;
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
}

.meta-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #666;
    font-size: 14px;
}

.sectors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
}

.sector-card {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    word-wrap: break-word;
    font-size: 16px;
}

.sector-card:hover {
    background-color: #f0f0f0;
}

.sector-card.selected {
    background-color: #e6f2ff;
    border-color: #007bff;
}

.global-search-container {
    margin-bottom: 20px;
    text-align: center;
}

.global-search-input {
    width: 100%;
    padding: 12px;
    border: 2px solid #007bff;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.global-search-input:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 91, 179, 0.5);
}

.global-search-results {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.global-search-results h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.sector-result {
    margin-bottom: 15px;
}

.sector-result h3 {
    font-size: 18px;
    color: #007bff;
}

.sector-result ul {
    margin: 10px 0;
    padding-left: 20px;
}

.search-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 2px solid #007bff;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f9f9f9;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 91, 179, 0.5);
}

.categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
}

.category-serial {
    margin-right: 10px;
    font-weight: bold;
    color: #333;
}

.category-card {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.category-label {
    display: flex;
    align-items: center;
    gap: 10px;
}

.fixed-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
}

.download-button,
.clear-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.download-button:hover {
    background-color: #0056b3;
}

.clear-button {
    background-color: #ff4d4d;
}

.clear-button:hover {
    background-color: #cc0000;
}

.download-button:disabled,
.clear-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.loading {
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    color: #666;
}
