/* ReportSelectionPage.css */
.report-selection-container {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .report-selection-container h1 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  .report-selection-container p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .report-selection-container input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .selection-list {
    margin-bottom: 80px; /* Ensure content doesn't hide behind the fixed next button */
  }
  
  .selection-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Increase the width of the serial number to prevent overlapping */
  .selection-item .serial-no {
    width: 60px;
    text-align: right;
    margin-right: 10px;
    font-weight: bold;
  }
  
  .selection-item input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .report-selection-container button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .report-selection-container button:hover {
    background-color: #0056b3;
  }
  
  /* Next button container fixed at the bottom */
  .next-button-container {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  