/* Scope all portal‑specific styles to the .government-portals container */
.government-portals {
  padding: 2rem 4rem; /* Desktop padding */
}

.government-portals ul {
  list-style: none;
  padding: 0;
}

.government-portals li {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Portal Grid Layout */
.portals-grid {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.portals-column {
  flex: 1;
  min-width: 45%;
  background: #eef2f7;
  padding: 20px;
  border-radius: 8px;
}

.portals-column h2 {
  text-align: center;
  color: #0056b3;
}

/* Portal Lists */
.central-portals-list a,
.state-portals-list a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.central-portals-list a:hover,
.state-portals-list a:hover {
  text-decoration: underline;
}

/* Serial Numbers */
.serial-number {
  font-weight: bold;
  color: #333;
  display: inline-block;
  width: 30px;
}

/* State Government Portal Styling */
.state-portal-item strong {
  margin-right: 10px;
  display: inline-block;
  width: 150px;
}

/* Last updated text */
.last-updated {
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
}

/* ---------- Mobile Styles ---------- */
@media (max-width: 768px) {
  /* Reduce overall horizontal padding for mobile */
  .government-portals {
    padding: 1rem;  /* Minimal horizontal padding */
  }
  
  .portals-grid {
    flex-direction: column;
    gap: 10px;
  }

  .portals-column {
    min-width: 100%;
    margin-bottom: 1rem;
    padding: 10px;  /* Reduced padding */
  }
  
  .portals-column h2 {
    font-size: 1.2rem;
  }
  
  .central-portals-list a,
  .state-portals-list a {
    font-size: 0.9rem;
  }
  
  .serial-number {
    width: 25px;
    font-size: 0.9rem;
  }
  
  .state-portal-item strong {
    width: 100px;
    font-size: 0.9rem;
  }
  
  .last-updated {
    font-size: 12px;
  }
}
