/* src/components/AboutUs.css */
.about-us-container {
    max-width: 800px;
    margin: 2rem auto;  /* 2rem top & bottom; auto left & right */
    padding: 2rem;
    text-align: left;
    background-color: #f4f4f4;
    border-radius: 10px;
}

.about-us-container h1 {
    color: #1e2a38;
    margin-bottom: 1rem;
}

.about-us-container p {
    color: #333;
    line-height: 1.6;
    margin-bottom: 1rem;
}
