/* src/components/ClientCarousel.css */
.carousel-container {
    text-align: center;
    margin: 2rem 0;
}

.carousel-container h2 {
    color: #1e2a38;
    margin-bottom: 1rem;
}

.carousel {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    animation: slide 20s linear infinite;
}

.carousel-item {
    display: inline-block;
    padding: 0 1rem;
}

.client-logo {
    height: 90px; /* Adjust height as needed */
    width: auto; /* Maintain aspect ratio */
    object-fit: contain;
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
