.central-tenders-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.multiline-text {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.multiline-link {
  display: block;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

.favorite-button {
  padding: 5px 10px;
  border: 2px solid black;
  cursor: pointer;
  background-color: lightgray;
}

.favorite-button.unmark {
  background-color: yellow;
}

/* Ensure consistent background color for rows */
.MuiDataGrid-row {
  background-color: #ffffff;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: #f0f0f0;
}

/* Mobile Overrides */
@media only screen and (max-width: 768px) {
  .central-tenders-container {
    padding: 10px;
  }

  /* Adjust the DataGrid container height if needed */
  .central-tenders-container > div[style*="height: 80vh"] {
    height: auto !important;
    max-height: 60vh;
    overflow-y: auto;
  }

  /* Adjust search controls layout on mobile */
  .search-controls {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }
  
  /* Adjust the search box width on mobile */
  .search-box {
    width: 200px !important;
  }
  
  /* Further reduce the go-to-page box width and adjust label font-size */
  .goto-page-box {
    width: 100px !important;
  }
  .goto-page-box .MuiInputLabel-root {
    font-size: 0.6rem !important;
  }
  
  /* Adjust the unmark button */
  .unmark-button {
    font-size: 0.8rem !important;
    padding: 6px 12px !important;
  }
  
  /* Adjust headings */
  .central-tenders-container h1,
  .central-tenders-container h3 {
    font-size: 1.2rem;
  }
}
