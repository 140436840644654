/* Global fix to prevent horizontal scroll */
html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main container styling */
.gem-bids-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f9f9f9;
}

/* Ensure content takes full width */
.content-container {
    max-width: 100% !important;
    width: 100% !important;
}

/* Table styling (if used) */
.gem-bids-table {
    width: 100%;
    border-collapse: collapse;
}

.gem-bids-table th, .gem-bids-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.gem-bids-table th {
    background-color: #007bff;
    color: white;
}

.gem-bids-table tr:hover {
    background-color: #f1f1f1;
}

/* App specific classes */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Mobile Overrides */
@media only screen and (max-width: 768px) {
  .gem-bids-container {
    padding: 10px;
  }
  
  /* Adjust the DataGrid container height if needed */
  .gem-bids-container > div[style*="height: 80vh"] {
    height: auto !important;
    max-height: 60vh;
    overflow-y: auto;
  }
  
  /* Adjust search controls layout on mobile */
  .search-controls {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }
  
  /* Adjust the search box, go-to-page box, and unmark button sizes */
  .search-box {
    width: 200px !important;
  }
  .goto-page-box {
    width: 100px !important;
  }
  .goto-page-box .MuiInputLabel-root {
    font-size: 0.8rem !important;
  }
  
  .unmark-button {
    font-size: 0.8rem !important;
    padding: 6px 12px !important;
  }
  
  /* Adjust headings */
  .gem-bids-container h1,
  .gem-bids-container h3 {
    font-size: 1.2rem;
  }
}
