/* ProcurementRules.css */

.procurement-table-container {
  max-width: 100%;
  overflow-x: auto;
  padding: 10px;
}

.procurement-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;
}

.procurement-table th, .procurement-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.procurement-table th {
  background-color: #f4f4f4;
}

.procurement-table a {
  color: #007bff;
  text-decoration: none;
}

.procurement-table a:hover {
  text-decoration: underline;
}

/* Adjusting column sizes for better spacing */
.procurement-table th:nth-child(1),
.procurement-table td:nth-child(1) {
  width: 10%;
  min-width: 50px;
  text-align: center;
}

.procurement-table th:nth-child(2),
.procurement-table td:nth-child(2) {
  width: 50%;
}

.procurement-table th:nth-child(3),
.procurement-table td:nth-child(3) {
  width: 40%;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .procurement-table-container {
    overflow-x: hidden;
  }

  .procurement-table {
    display: block;
  }

  .procurement-table thead {
    display: none;
  }

  .procurement-table tbody tr {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .procurement-table td {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border: none;
  }

  .procurement-table td:before {
    content: attr(data-label);
    font-weight: bold;
    color: #333;
  }
}
