/* Center the content and constrain its width */
.tender-selection-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    text-align: center;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading styling */
  .tender-selection-container h1 {
    color: #1e2a38;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  
  /* Subtitle paragraph styling */
  .tender-selection-container p {
    color: #555;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }
  
  /* Unordered list styling */
  .tender-selection-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* List items styling */
  .tender-selection-container ul li {
    margin: 0.5rem 0;
  }
  
  /* Link styling: make them look like buttons */
  .tender-selection-container ul li a {
    display: inline-block;
    text-decoration: none;
    color: #00bcd4;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: 2px solid #00bcd4;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* On hover, invert the colors */
  .tender-selection-container ul li a:hover {
    color: #ffffff;
    background-color: #00bcd4;
  }
  
  /* Mobile Adjustments */
  @media (max-width: 768px) {
    .tender-selection-container {
      padding: 1.5rem;
      margin: 1rem;
    }
    
    .tender-selection-container h1 {
      font-size: 1.8rem;
    }
    
    .tender-selection-container p {
      font-size: 1rem;
    }
    
    .tender-selection-container ul li a {
      width: 100%;
      box-sizing: border-box;
    }
  }
  