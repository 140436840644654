/* Ensure the entire layout container fills the viewport without horizontal overflow */
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Content container expands to fill available space */
.content-container {
  flex: 1;
  padding: 20px;
  overflow: auto;
}
