/* Ensure the scoped container prevents horizontal overflow */
.govtdeptandorg {
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Global pointer for all clickable elements within the container */
.govtdeptandorg .accordion-header,
.govtdeptandorg .department-header,
.govtdeptandorg .clickable-label {
  cursor: pointer !important;
}

/* Accordion Grid: display sections side by side */
.govtdeptandorg .accordion-grid {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.govtdeptandorg .accordion-section {
  flex: 1;
  min-width: 300px;
}

/* Accordion Container */
.govtdeptandorg .accordion-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Accordion Item */
.govtdeptandorg .accordion-item {
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
}

/* Accordion Header: full-width clickable area with padding */
.govtdeptandorg .accordion-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  background-color: #f7f7f7;
  user-select: none;
}

/* Clickable label spans full area */
.govtdeptandorg .clickable-label {
  display: block;
  width: 100%;
  cursor: pointer !important;
}

/* Department Header */
.govtdeptandorg .department-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  background-color: #fff;
  user-select: none;
}

/* Indentation for nested department items */
.govtdeptandorg .department-item {
  margin-left: 20px;
  margin-top: 5px;
}

/* Organization List Styling */
.govtdeptandorg .organization-list {
  margin-left: 20px;
  padding-left: 20px;
  list-style-type: none;
}

/* Toggle Icon Styling: now immediately after the text */
.govtdeptandorg .toggle-icon {
  font-weight: bold;
  margin-left: 10px;
}

/* Fixed Download Button Styling */
.govtdeptandorg .fixed-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

/* Download Button Styling */
.govtdeptandorg .download-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Disabled State for Download Button */
.govtdeptandorg .download-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Loader container, centered content */
.govtdeptandorg .loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Enough vertical space to center spinner nicely */
}

/* Simple spinner using CSS border tricks */
.govtdeptandorg .spinner {
  border: 16px solid #f3f3f3;    /* Light grey */
  border-top: 16px solid #555;  /* Dark top border for contrast */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

/* Keyframes for rotating the spinner */
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}