/* ReportsPricing.css */
.reports-pricing-container {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.reports-pricing-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.reports-pricing-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

.reports-buttons {
  display: flex;
  justify-content: space-between;
}

.reports-buttons button {
  flex: 1;
  margin: 0 5px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
}

.reports-buttons button:hover {
  background-color: #0056b3;
}
